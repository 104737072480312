const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export default {
  staging: {
    serverUrl: SERVER_URL || 'https://pefpiapis.herokuapp.com/api/',
  },
  development: {
    serverUrl: SERVER_URL || 'https://dev.likemetric.com/server/api/',
  },
  production: {
    serverUrl: SERVER_URL || 'https://dev.likemetric.com/server/api/',
  },
};
